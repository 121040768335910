import {create} from 'apisauce'
import authStorage from './storage'

const base_url = process.env.REACT_APP_API_URL
const api = create({
  baseURL: base_url,
})

api.addAsyncRequestTransform(async (request) => {
  const authToken = await authStorage.get_token()
  if (!authToken) return
  request.headers['Authorization'] = `Bearer ${authToken}`
})

export default api
