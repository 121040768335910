import {FC, ReactNode, createContext} from 'react'
import {useLocation, Link as ScrollLink} from 'react-router-dom'
import {Outlet} from 'react-router-dom'
import {Box, Container, Typography, Link} from '@material-ui/core'
import {styled, useTheme} from '@material-ui/core/styles'

import MainNavbar from './MainNavbar'
import MainFooter from './MainFooter'
import Logo from 'src/components/Logo'

interface MainLayoutProps {
  children?: ReactNode
}

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const MainStyle = styled('div')(({theme}) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export const AuthContext = createContext({})

const MainLayout: FC<MainLayoutProps> = () => {
  const {pathname} = useLocation()
  const theme = useTheme()
  const isHome = pathname === '/'
  return (
    <>
      <MainNavbar />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Outlet />
      </MainStyle>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container maxWidth="lg">
            {/*
        // @ts-ignore */}
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{mb: 1, mx: 'auto', cursor: 'pointer'}} />
            </ScrollLink>

            <Typography variant="caption" component="p">
              © All rights reserved
              <br /> made by &nbsp;
              <Link href="https://minimals.cc/">minimals.cc</Link>
            </Typography>
          </Container>
        </Box>
      )}
    </>
  )
}

export default MainLayout
