import PropTypes from 'prop-types'

import {useMemo} from 'react'
// material
import {CssBaseline} from '@material-ui/core'
import {createTheme, ThemeProvider, StyledEngineProvider} from '@material-ui/core/styles'
// hooks
import useSettings from './hooks/useSettings'
//
import shape from './schemes/shape'
import palette from './schemes/palette'
import typography from './schemes/typography'
import breakpoints from './schemes/breakpoints'
import GlobalStyles from './schemes/globalStyles'
import componentsOverride from './overrides'
import shadows, {customShadows} from './schemes/shadows'

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
}

export default function ThemeConfig({children}) {
  const {themeMode, themeDirection} = useSettings()
  const isLight = themeMode === 'light'

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? {...palette.light, mode: 'light'} : {...palette.dark, mode: 'dark'},
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection],
  )
  // @ts-ignore: Unreachable code error
  const theme = createTheme(themeOptions)
  theme.components = componentsOverride(theme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
