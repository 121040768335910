import SecureLS from 'secure-ls'
const ls = new SecureLS({encodingType: 'aes'})

const key = 'token'
const user = 'user'
const temp_token = 'temp_token'

const store_token = (token) => {
  try {
    ls.set(key, token)
  } catch (error) {
    console.log('Error storing the auth token', error)
  }
}

const store_user = (data) => {
  try {
    ls.set(user, JSON.stringify(data))
  } catch (error) {
    console.log('Error storing the user', error)
  }
}

const store_temp_token = (token) => {
  try {
    ls.set(temp_token, token)
  } catch (error) {
    console.log('Error storing the temporary token', error)
  }
}

const get_token = () => {
  try {
    return ls.get(key)
  } catch (error) {
    console.log('Error getting the auth token', error)
  }
}

const get_user = async () => {
  try {
    return await ls.get(user)
  } catch (error) {
    console.log('Error getting the user', error)
    return false
  }
}

const get_temp_token = async () => {
  try {
    return await ls.get(temp_token)
  } catch (error) {
    console.log('Error getting the temporary token', error)
    return false
  }
}

const remove_temp_token = async () => {
  try {
    return await ls.remove(temp_token)
  } catch (error) {
    console.log('Error getting the temporary token', error)
    return false
  }
}

const remove = () => {
  try {
    ls.remove(key)
    ls.remove(user)
    ls.remove(temp_token)
  } catch (error) {
    console.log('Error removing the token', error)
  }
}

const _expObject = {
  get_token,
  get_user,
  get_temp_token,
  remove,
  remove_temp_token,
  store_token,
  store_user,
  store_temp_token,
}

export default _expObject
