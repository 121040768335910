// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    login: '/login',
  },
  dashboard: {
    app: path(ROOTS_DASHBOARD, '/app'),
    gigs: path(ROOTS_DASHBOARD, '/gigs'),
  },
}
