import {User, UserList, ResendVerification} from 'src/models/User'
import api_request from 'src/utils/header'

const get_user = (_id: string) => api_request.get<User>(`/user`)

const get_user_list = (page: number) => api_request.get<UserList>(`/user/list?page=${page}`)

const get_user_search = (page: number, query: string) =>
  api_request.get<UserList>(`/user/search?page=${page}&keyword=${query}`)

const get_user_specific = (id: string, type: string) => api_request.get<User>(`/user/${id}/${type}`)

const post_verification_code = (email: string) =>
  api_request.post<ResendVerification>(`/auth/resend-verification`, {email: email})

const get_export_users = () => api_request.get<UserList>(`/user/export`)

const exp_object = {
  get_user,
  get_user_list,
  get_user_specific,
  post_verification_code,
  get_user_search,
  get_export_users,
}

export default exp_object
