// ----------------------------------------------------------------------

export default function IconButton(theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
    },
  }
}
