// ----------------------------------------------------------------------

export default function Snackbar(theme) {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {},
      },
    },
  }
}
