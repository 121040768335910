import {useEffect} from 'react'
import {Link as RouterLink, Outlet} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import {styled} from '@material-ui/core/styles'
import Logo from 'src/components/Logo'
import storage from '../../../utils/storage'
const HeaderStyle = styled('header')(({theme}) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}))

export default function LogoOnlyLayout() {
  // const navigation = useNavigate()

  const check_login = async () => {
    const token = await storage.get_token()
    const current_date = new Date()
    if (!token || jwt_decode(token)['exp'] * 1000 < current_date.getTime()) {
      await storage.remove()
      // return navigation('/login')
    }
    // navigation('/dashboard')
  }

  useEffect(() => {
    check_login()
    // eslint-disable-next-line @typescript-eslint/no-redeclare
  }, [])

  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  )
}
