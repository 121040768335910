import {NavLink as RouterLink, useLocation} from 'react-router-dom'
// material
import {styled} from '@material-ui/core/styles'
import {Box, AppBar, Toolbar, Container, Typography, Link} from '@material-ui/core'
// hooks
import useOffSetTop from 'src/theme/hooks/useOffSetTop'
// components
import MHidden from 'src/components/@extend/MHidden'
//
import MenuDesktop from './Menu/MenuDesktop'
import navConfig from './Menu/MenuConfig'
import HeaderButtons from './Header/Buttons'
import HeaderUserbox from './Header/Userbox'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 88

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }), // @ts-ignore: Unreachable code error
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP,
  },
}))

const ToolbarShadowStyle = styled('div')(({theme}) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`, // @ts-ignore: Unreachable code error
  boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100)
  const {pathname} = useLocation()
  const isHome = pathname === '/'

  return (
    <AppBar sx={{boxShadow: 0, bgcolor: 'transparent'}}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: {md: APP_BAR_DESKTOP - 16},
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link component={RouterLink} to="/" underline="none">
            <Typography variant="h6">Starjobs B2B </Typography>
          </Link>
          {/*
        // @ts-ignore */}
          <Box sx={{flexGrow: 1}} />
          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <HeaderButtons />
          <HeaderUserbox />
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  )
}
