import {Link as ScrollLink} from 'react-scroll'
import {Link as RouterLink} from 'react-router-dom'
// material
import {styled} from '@material-ui/core/styles'
import {Grid, Link, Divider, Container, Typography, Stack} from '@material-ui/core'
import Logo from 'src/components/Logo'

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Legal',
    children: [
      {name: 'Terms and Condition', href: '#'},
      {name: 'Privacy Policy', href: '#'},
    ],
  },
  {
    headline: 'Contact',
    children: [
      {name: 'support@starjobs.com.ph', href: '#'},
      {name: 'Los Angeles, 359  Hidden Valley Road', href: '#'},
    ],
  },
]

const RootStyle = styled('div')(({theme}) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{pt: 10}}>
        <Grid
          container
          justifyContent={{xs: 'center', md: 'space-between'}}
          sx={{textAlign: {xs: 'center', md: 'left'}}}
        >
          <Grid item xs={12} sx={{mb: 3}}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{mx: {xs: 'auto', md: 'inherit'}}} />
            </ScrollLink>
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack spacing={5} direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
              {LINKS.map((list) => {
                const {headline, children} = list
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{display: 'block'}}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: {xs: 'center', md: 'center'},
          }}
        >
          Starjobs App - Admin © 2021. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  )
}
