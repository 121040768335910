import AppRoute from './routes'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

import ThemeProvider from './theme'
import ThemePrimaryColor from './theme/ThemePrimaryColor'
import NotistackProvider from './theme/NotistackProvider'

import {CssBaseline} from '@material-ui/core'

import {UsersProvider} from './contexts/UsersContext'

const App = () => {
  return (
    <ThemeProvider>
      <ThemePrimaryColor>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <UsersProvider>
            <CssBaseline />
            <NotistackProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppRoute />
              </LocalizationProvider>
            </NotistackProvider>
          </UsersProvider>
        </LocalizationProvider>
      </ThemePrimaryColor>
    </ThemeProvider>
  )
}
export default App
