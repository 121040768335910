import {FC, useEffect, useState, createContext} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import storage from 'src/utils/storage'
import user_api from 'src/api/user'
import auth_api from 'src/api/login'

// eslint-disable-next-line @typescript-eslint/no-redeclare
type UserContext = {}
const UsersContext = createContext({} as UserContext)

const UsersProvider: FC = ({children}) => {
  const pathname = useLocation()
  const [user, setUser] = useState<any>()
  const navigation = useNavigate()

  const login = async (values) => {
    if (!values.email || !values.password)
      return {
        msg: 'Missing fields',
        status: false,
      }

    const result = await auth_api.sign_in_email(values)
    if (!result.ok) {
      return {
        msg: 'Invalid credentials',
        status: false,
      }
    }

    let data: any = result.data
    if (data.accountType !== 1)
      return {
        msg: 'You are not allowed to access this portal',
        status: false,
      }

    await storage.store_token(data['token'])
    await storage.store_user(data)
    setUser(data)
    return {
      msg: 'Success Login',
      status: true,
    }
  }

  const get_updated_user_details = async () => {
    const token = await storage.get_token()
    const result = await user_api.get_user(jwt_decode(token)['id'])

    if (result.data['accountType'] !== 1) return navigation('/login')
    setUser(result.data)
  }

  const check_active = () => {
    return user.isActive ? true : false
  }

  const check_token_expire = async () => {
    const token = await storage.get_token()
    if (!token) return navigation('/login')

    const expiry = JSON.parse(atob(token.split('.')[1])).exp

    if (Math.floor(new Date().getTime() / 1000) >= expiry) {
      await storage.remove()
      return navigation('/login')
    }
  }

  const logout = async () => {
    await storage.remove()
    navigation('/login')
  }

  const getUser = async () => {
    const result = await storage.get_user()
    return JSON.parse(result)
  }

  useEffect(() => {
    check_token_expire()
    // eslint-disable-next-line
  }, [])

  return (
    <UsersContext.Provider
      value={{user, setUser, getUser, login, check_token_expire, get_updated_user_details, check_active, logout}}
    >
      {children}
    </UsersContext.Provider>
  )
}

export {UsersContext, UsersProvider}
