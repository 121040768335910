import {useRef, useState, useContext, useEffect} from 'react'
import {NavLink} from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@material-ui/core'

import {experimentalStyled} from '@material-ui/core/styles'
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone'
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone'
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone'

import {UsersContext} from 'src/contexts/UsersContext'

const UserBoxButton = experimentalStyled(Button)(
  ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
)

const MenuUserBox = experimentalStyled(Box)(
  ({theme}) => `
        padding: ${theme.spacing(2)};
`,
)

const UserBoxText = experimentalStyled(Box)(
  ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
)

const UserBoxLabel = experimentalStyled(Typography)(
  ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
)

const UserBoxDescription = experimentalStyled(Typography)(
  ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
)

function HeaderUserbox() {
  const {user, getUser, logout}: any = useContext(UsersContext)
  const [profile, setProfile] = useState([])

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    const load = async () => {
      if (!user) {
        const data = await getUser()
        return setProfile(data)
      }
      setProfile(user)
    }
    load()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={profile['name']} src={profile['avatar']} />
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ml: 1}} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{minWidth: 210}} display="flex">
          <Avatar variant="rounded" alt={profile['name']} src={profile['avatar']} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{profile['name']}</UserBoxLabel>
            <UserBoxDescription variant="body2">Administrator</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{mb: 0}} />
        <List sx={{p: 1}} component="nav">
          <ListItem button to="/dashboard/settings" component={NavLink}>
            <AccountTreeTwoToneIcon fontSize="small" sx={{color: '#323232', mx: 2}} />
            <ListItemText primary="Account Settings" sx={{color: '#323232', fontSize: '1rem'}} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{m: 1}}>
          <Button color="primary" fullWidth onClick={logout}>
            <LockOpenTwoToneIcon sx={{mr: 1}} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
