import {LoginDefault} from 'src/models/Auth'
import api_request from 'src/utils/header'

const sign_in_email = async (form_data) => {
  return api_request.post<LoginDefault>('/auth/sign-in', form_data)
}

const exp_object = {
  sign_in_email,
}

export default exp_object
